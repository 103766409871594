<template>
  <main role="main" style="height: 100%">
    <Player
      @endplaylist="endPlaylist"
      ref="pl"
      :data="{}"
      :test="true"
      :advertise-data="advertiseData"
    ></Player>
    <button @click.prevent="$refs.pl.play()">zxzz</button>
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Player from "@/components/Player";
import isFlight from "@/mixins/isFlight";

export default {
  name: "Preload",
  mixins: [isFlight],
  components: { Player },
  computed: {
    ...mapState("user", {
      authenticate: state => state.authenticate
    }),
    ...mapState("settings", {
      settingsIndexLoading: state => state.indexLoading,
      indexCaptive: state => state.index
    })
  },
  data: () => {
    return {
      user: {
        firstName: "",
        lastName: "",
        seat: ""
      },
      advertiseData: {},
      errors: false
    };
  },
  mounted() {
    this.setSettingsEntity(["preload", true]);
    this.$router.push("/");
    this.advertiseData = {
      adRolls: { preroll: { adVideos: this.indexCaptive.captivePage.ads } }
    };
  },
  methods: {
    ...mapActions("user", {
      loginAction: "loginUser"
    }),
    ...mapMutations("settings", {
      setSettingsEntity: "SET_ENTITY"
    }),
    endPlaylist() {
      this.setSettingsEntity(["preload", true]);
      this.$router.push("/");
    },

    async submit() {
      await this.loginAction(this.user);

      this.errors = !this.authenticate;
      if (this.authenticate) {
        await this.$router.push("/");
      }
    },
    ...mapActions({
      setLocale: "SET_LOCALE"
    })
  }
};
</script>

<style scoped></style>
